import CryptoJS from "crypto-js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const forArr = (
  arr: string | any[],
  callback: { (item: Record<string, any>): void; call?: any }
) => {
  for (let i = 0; i < arr.length; i++) {
    if (callback.call(null, arr[i], i)) {
      break;
    }
  }
};
export const formatNumber = (num: number, unit = "万") => {
  if (num > 10000) {
    return Number((num / 10000).toFixed(1)) + unit;
  } else {
    return num;
  }
};

export const parseTime = (time: any, cFormat?: string) => {
  if (!time) return null;
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj: Record<string, any> = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  return format.replace(
    /{(y|m|d|h|i|s|a)+}/g,
    (result: string | any[], key: string) => {
      let value = formatObj[key];
      if (key === "a")
        return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
      if (result.length > 0 && value < 10) {
        value = "0" + value;
      }
      return value || 0;
    }
  );
};
// 处理form表单提交形式
export const qsStringfy = (params: { [x: string]: string }) => {
  return Object.keys(params)
    .map((v) => `${v}=${encodeURI(params[v])}`)
    .join("&");
};

export const getUrlParams = (url: string) => {
  const result: Record<string, any> = {};
  const reg = /[?&][^?&]+=[^?&]+/g;
  const newSearch = url.match(reg);

  if (newSearch) {
    newSearch.forEach((item: string) => {
      const temp = item.substring(1).split("=");
      result[temp[0]] = temp[1];
    });
  }

  return result;
};
// 格式化时间
export const formatTime = (datetime: string | number | Date, type?: number) => {
  const date = datetime ? new Date(datetime) : new Date();
  const dataString = `${date.getFullYear()}-${String(
    "0" + (date.getMonth() + 1)
  ).substr(-2)}-${String("0" + date.getDate()).substr(-2)}`;
  if (type === 1) {
    return dataString;
  } else {
    return `${dataString} ${String("0" + date.getHours()).substr(-2)}:${String(
      "0" + date.getMinutes()
    ).substr(-2)}`;
  }
};
// 加密
export function encryption(pwd: string, key: string, iv: string) {
  pwd = CryptoJS.enc.Utf8.parse(pwd); // 解析明文
  key = CryptoJS.enc.Utf8.parse(key); // 解析密钥
  iv = CryptoJS.enc.Utf8.parse(iv);
  const encrypted = CryptoJS.AES.encrypt(pwd, key, {
    mode: CryptoJS.mode.CBC, // 加密模式
    padding: CryptoJS.pad.Pkcs7, // 填充方式
    iv, // 向量
  });
  return encrypted.toString(); // 加密后的结果是对象，要转为文本

  // 加上ciphertext 就变成十六进制的了
  // return encrypted.ciphertext.toString()
}

// 解密
export function decrypt(data: string, key: string, iv: string) {
  // // 如果加密时候用了ciphertext.toString() 就得加上下面这两行
  // data = CryptoJS.enc.Hex.parse(data)
  // data = CryptoJS.enc.Base64.stringify(data)
  key = CryptoJS.enc.Utf8.parse(key); // 解析密钥
  iv = CryptoJS.enc.Utf8.parse(iv);
  const encrypted = CryptoJS.AES.decrypt(data, key, {
    // 解析的密文必须是base64编码（data） 这很关键！
    mode: CryptoJS.mode.CBC, // 加密模式
    padding: CryptoJS.pad.Pkcs7, // 填充方式
    iv, // 向量
  });
  return encrypted.toString(CryptoJS.enc.Utf8); // 加密后的结果是对象，要转为文本
}
// 根据域名那站点的配置参数
export function getHostName() {
  if (window.location.hostname.indexOf("-") > 0) {
    const hostName = window.location.hostname.split("-")[0].toString();
    return hostName;
  }
  return "";
  // if (!hostName || hostName == "h5") {
  //   hostName = getUrlParams(window.location.hash).hostName;
  // }
}

//判断数组中是否包含某字符串
export const contains = (sss: Record<string, any>, needle: any) => {
  for (const i in sss) {
    if (sss[i].indexOf(needle) > 0) return i;
  }
  return -1;
};
// 获取设备型号
export const getMobile = (md: Record<string, any>, device_type: string) => {
  let model = "";
  if (md.os() == "iOS") {
    //ios系统的处理
    //  model = md.mobile() + md.version("iPhone")  ;
    model = "";
  } else if (md.os() == "AndroidOS") {
    //Android系统的处理
    const sss: any = device_type.split(";");
    const i = contains(sss, "Build/");

    if ( Number(i) > -1) {
      model = sss[i].substring(0, sss[i].indexOf("Build/"));
    }
  }
  return model;
};

// 获取设备唯一标识
export const getDeviceId = async () => {
  const fp = await FingerprintJS.load();

  const result = await fp.get();

  const visitorId = result.visitorId;
  // console.log(visitorId);
  return visitorId;
};

// 获取页面参数
export const getQuery = (url: string) => {
  // str为？之后的参数部分字符串
  const str = url.substr(url.indexOf('?') + 1)
  // arr每个元素都是完整的参数键值
  const arr = str.split('&')
  // result为存储参数键值的集合
  const result: Record<string, string> = {}
  for (let i = 0; i < arr.length; i++) {
      // item的两个元素分别为参数名和参数值
      const item = arr[i].split('=')
      result[item[0]] = item[1]
  }
  return result
}

export const isValidEmail = (email:string) => {
  // 使用正则表达式来匹配电子邮件格式
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

/*判断客户端*/
export const judgeClient = () => {
  let client = '';
  if (/(iPhone|iPad|iPod|iOS|Macintosh|Mac OS X)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
    client = 'iOS';
  } else {  //判断Android
    client = 'Android';
  } 
  return client;
}

export function generateUniqueId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// 是否是移动端
export function isTouchDevice() {
	return ('ontouchstart' in window);
};